@import '../../../styles//variables';

.breadcrumbItem {
  color: $redesign-table-showing-color;
  &::after {
    content: '  /'
  }
  margin-right: 6px;
}

.breadcrumbItemActive {
  color: $redesign-accent-color;
  &::after {
    content: ''
  }
}