.pagination-container {
  display: flex;
  justify-content: space-between;
  align-items: center;

  & .pagination-el {
    font-size: 18px;

    & input {
      margin-left: 10px;
      border: $border;

      &:focus {
        border: 1px solid $base-a-color;
      }
    }
  }
}

.pagination-btn-container {
  display: flex;
  justify-content: space-between;
}

.pagination-btn {
  border: 1px solid $base-grey-color-2;
  padding: 1px 19px;

  line-height: 18px;

  cursor: pointer;

  &:hover {
    & .pagination-btn-icon {
      color: $accent-color;
    }
  }

  & .disabled-btn {
    color: $base-grey-color-2 !important;
  }

  & .pagination-btn-icon {
    height: 30px;
    font-size: 25px;
    color: $base-text-color-2;
  }
}

.table-container {
  width: 100%;
  max-width: 1260px;
  margin-bottom: 20px;

  font-size: 14px;
  text-align: left;

  table-layout: fixed;
  border-collapse: collapse;

  &.enterprise-table {
    tr th {
      &:first-child {
        width: 350px;
      }
      &:nth-child(3) {
        width: 100px;
      }
    }
  }

  &.role-table {
    td {
      cursor: auto;
    }
  }

  tr:last-child td {
    border-bottom: 0;
  }

  tr th {
    border-bottom: 1px solid $border-color-3;
    font-size: 19px;
    color: $border-color-3;

    &:nth-child(3) {
      width: 115px;
    }

    &:first-child {
      width: 280px;
    }

    &:last-child,
    &:nth-last-child(2) {
      width: 200px;
    }

    &:before {
      position: absolute;
      top: 30px;
      right: 15px;

      width: 0;
      height: 0;
      border-right: 5px solid transparent;
      border-left: 5px solid transparent;

      content: '';
    }

    &.sort-asc::before {
      border-bottom: 5px solid $base-a-color;
    }

    &.sort-desc::before {
      border-top: 5px solid $base-a-color;
    }
  }

  tbody tr {
    &:hover {
      background: $base-grey-color-3;
    }
    &:nth-child(even) {
      background: $base-grey-color-3;
    }
  }

  th,
  td {
    position: relative;

    margin: 0;
    border-bottom: 1px solid $base-grey-color-2;
    padding: 0.5rem;
    padding: 20px 0px 20px 10px;

    font-size: 15px;

    cursor: pointer;

    &:last-child {
      border-right: 0;
    }
  }
}
