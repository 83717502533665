@import '../../../styles/variables';

.button {
  background-color: $color-white;
  border: 1px solid $redesign-accent-color;
  border-radius: 4px;
  color: $redesign-accent-color;
  font-weight: 500;
  padding: 6px 0px;
  width: 146px;

  &:hover {
    opacity: .7;
  }

  &:active, &:focus {
    border: 1px solid $redesign-accent-color;
  }

  svg {
    margin-left: 6px;
    position: relative;
    bottom: 2px;
  }
}