.spinner {
  position: absolute;
  top: 300px;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;

  width: auto;
  height: 300px;
}
