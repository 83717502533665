@import '../../../styles/variables';

.modal {
  background-clip: padding-box;
  background-color: $color-white;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  position: relative;
  width: 100%;
}

.modalBodyHeader {
  border-bottom: 1px solid $redesign-border-color;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  padding: 16px;
}

.modalBody {
  padding: 0;
}

.topRow,
.modalRow {
  border-bottom: 1px solid $redesign-border-color;
  color: #373a3c;
  display: grid;
  font-size: 16px;
  font-weight: 400;
  padding: 16px;

  span:first-child {
    font-weight: 700;
  }
}

.topRow {
  grid-template-columns: 205px 170px auto;

  span {
    justify-self: start;
    text-align: left;
    word-break: break-all;
  }
}

.modalRow {
  grid-template-columns: 205px auto;

  span {
    justify-self: start;
  }
}