.nav-menu {
  top: 0;

  width: 100%;
  height: 71px;
  margin-bottom: 10px;

  background-color: $color-white;
  box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);

  & .nav-container {
    max-width: 1260px;
    height: 100%;
    padding: 0;
  }

  & .nav-container-row {
    display: flex;
    justify-content: space-between;

    height: 100%;
    margin-top: 0;
  }
}

.menu-container {
  position: relative;
  display: flex;
  align-items: center;

  width: 100%;
  height: 100%;

  &.side-menu {
    justify-content: center;
    margin-bottom: 20px;

    &:last-child {
      margin-top: 150px;
    }

    & .selected,
    & .menu-el:hover {
      color: $base-text-color !important;
      text-decoration: underline;
    }

    & .menu-el {
      font-size: 19px;
      color: $base-grey-color;
    }
  }

  &.navbar-menu {
    &:nth-child(2) {
      justify-content: center;
      margin-left: 65px;
    }

    &:last-child {
      justify-content: flex-end;
    }

    & .menu-el {
      margin-right: 35px;
    }

    & .nav-active {
      color: $redesign-accent-color;
    }
  }

  & .menu-el {
    font-family: $helvetica;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
    color: $base-text-color;
    text-transform: uppercase;

    &:hover {
      color: $base-grey-color;
    }
  }

  & .menu-account {
    display: block;

    width: 37px;
    padding: 0 10px;

    font-size: 11px;
    color: $base-text-color;

    &:hover {
      color: $accent-color;
    }

    .account-icon {
      width: 17px;
      height: 19px;
      cursor: pointer;

      &:hover .account-icon-path {
        stroke: $accent-color;
      }
    }
  }
}

.menu-sub {
  position: absolute;
  top: 55px;
  right: 0;
  display: block;
  visibility: hidden;

  min-width: 210px;
  max-width: 300px;
  border-top: 16px solid transparent;
  border-radius: 2px;
  padding: 15px;

  text-align: left;
  white-space: normal;
  word-break: keep-all;

  background-color: $color-white;
  // Styles necessary to maintain dropdown visibility
  background-clip: padding-box; // prevents background from appearing under the transparent border.
  list-style: none;

  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.2));

  // creates a shadow around the visible shape and not the box

  &.menu-sub-visible {
    top: 55px;
    visibility: visible;
  }
}

.menu-sub-arrow-head {
  position: absolute;
  top: 0;
  right: 15px;

  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: $color-white $color-white transparent transparent;

  box-shadow: 2px -2px 4px 0 rgba(0, 0, 0, 0.05);

  transform: rotate(-45deg);
  transform-origin: 0 0;
}

.menu-sub-item {
  &:not(:last-of-type) {
    margin-bottom: 10px;
  }

  & .menu-sub-link {
    font-weight: 900;
    color: $base-text-color;
    text-transform: uppercase;

    &:hover {
      color: $accent-color;
    }
  }
}

.side-menu-container {
  position: relative;
  border-right: 2px solid $base-grey-color;

  ul {
    text-align: center;
  }
}
