@import '../../../styles//variables';

.wrapper {
  border-radius: 4px;
}

.row {
  border: 1px solid $redesign-border-color;
  color: #212529;
  display: grid;
  grid-template-columns: 205px auto;
  column-gap: 20px;
  font-size: 16px;
  line-height: 24px;
  padding: 16px;

  span {
    color: $redesign-category-text-color;
    font-weight: 700;
  }
}