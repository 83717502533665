.modal-content {
  padding: 30px 0 10px;
  font-size: 20px;
}

.modal-body {
  text-align: center;
}

.modal-footer {
  justify-content: center;
}
