@import '../../../styles//variables';

.wrapper {
  margin: 0 auto;
  max-width: 700px;
}

.title {
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  margin-bottom:30px;
  padding-top: 14px;
}

.inputGroup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 36px 92px;
  margin-bottom: 30px;
}

.select {
  display: block;
  width: 100%;
}

.optionalText {
  color: $redesign-table-showing-color;
}

.popoverBodyHeader {
  font-weight: 700;
  text-align: center;
  margin: 0;
}

.popoverBodyText {
    text-align: center;
    font-style: italic;
    margin: 0;
    margin-bottom: 5px;
}

.previewPlanButton {
  background-color: $color-white;
  border: 1px solid $redesign-accent-color;
  border-radius: 4px;
  color: $redesign-accent-color;
  font-size: 20px;
  line-height: 30px;
  padding: 8px 0px;
  width: 100%;

  &:active, &:focus {
    border: 1px solid $redesign-accent-color;
  }

  &:focus {
    border-color: $redesign-form-focus;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  }

  &:hover {
    opacity: .7;
  }

  &:disabled {
    border: 1px solid $redesign-table-showing-color;
    color: $redesign-table-showing-color;

    &:hover {
      opacity: 1;
      cursor: not-allowed;
    }
  }
}