@import '../../../styles//variables';

.showingDisplay {
  color: $redesign-table-showing-color;
  margin-bottom: 30px;
}

.table {
  border: 1px solid $redesign-border-color;
  border-radius: 4px;
  color: #212529;
  margin-bottom: 30px;
  width: 100%;

  col:first-child {
    width: 24%;
  }

  col:nth-child(2) {
    width: 36%;
  }

  col:nth-child(3) {
    width: 16%;
  }

  col:nth-child(4) {
    width: 24%;
  }
}

.tableHead {
  border-bottom: 2px solid $redesign-border-color;
}

.tableHeader {
  padding: 12px;

  span {
    margin-right: 8.54px;
  }
}

.tableBody {
  tr:nth-child(even) {
    background-color: $redesign-table-color;
  }

  td {
    padding: 12px;
  }
}

.dataCellWrapper {
  display: flex;
  justify-content: space-between;
}

.copyButton {
  margin-right: 15%;
}

.pagination {
  margin-left: auto;
  width: fit-content;
}