.page-not-found {
  font-family: $labGrotesque;
  font-weight: 900;
  color: $base-text-color-2;
  text-align: center;

  & .page-error-header {
    font-size: 76px;
  }

  & .page-error-body {
    font-size: 30px;
  }
}
