.page-go-back {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  width: 105px;
  margin-bottom: 25px;

  font-size: 20px;

  outline: 0;
  cursor: pointer;

  &:hover {
    color: $accent-color;
  }

  span {
    margin-left: 5px;
  }
}

.link-inline {
  display: inline;

  margin-left: 15px;

  font-weight: bold;
  color: $accent-color;
  text-decoration: underline;

  cursor: pointer;

  &:hover {
    color: mix(white, $accent-color, 20%);
    text-decoration: underline;
  }
}

.inline-icon {
  margin-top: 4px;
  margin-left: 10px;

  color: $base-a-color;

  cursor: pointer;

  &:hover {
    color: $accent-color;
  }
}