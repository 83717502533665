// Generic Mixins

// Mixins for min-width breakpoints and up
// For example usage see _right-rail.scss and _site-skin.scss
@mixin breakpoint-small {
  @media (min-width: $screen-sm-min) {
    @content;
  }
}

@mixin breakpoint-medium {
  @media (min-width: $screen-md-min) {
    @content;
  }
}

@mixin breakpoint-large {
  @media (min-width: $screen-lg-min) {
    @content;
  }
}

@mixin breakpoint-xlarge {
  @media (min-width: $screen-xl-min) {
    @content;
  }
}

// Mixins for exact breakpoints only
@mixin breakpoint-small-only {
  @media (min-width: $screen-sm-min) and (max-width: $screen-sm-max) {
    @content;
  }
}

@mixin breakpoint-medium-only {
  @media (min-width: $screen-md-min) and (max-width: $screen-md-max) {
    @content;
  }
}

@mixin breakpoint-large-only {
  @media (min-width: $screen-lg-min) and (max-width: $screen-lg-max) {
    @content;
  }
}

@mixin transform($transforms...) {
  -webkit-transform: $transforms;
  -moz-transform: $transforms;
  -ms-transform: $transforms;
  -o-transform: $transforms;
  transform: $transforms;
}

@mixin transition($transitions...) {
  -webkit-transition: $transitions;
  -moz-transition: $transitions;
  -ms-transition: $transitions;
  -o-transition: $transitions;
  transition: $transitions;
}

@mixin animation($animations...) {
  -webkit-animation: $animations;
  -moz-animation: $animations;
  -ms-animation: $animations;
  -o-animation: $animations;
  animation: $animations;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{$animationName} {
    @content;
  }

  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-ms-keyframes #{$animationName} {
    @content;
  }

  @-o-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@mixin ratio($x, $y) {
  padding-top: percentage($y / $x);
}
