.search-form-container {
  display: flex;
  width: 100%;
}

.enterprise-search-box {
  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 275px;
}

.enterprise-search-input {
  width: 250px;
  & .enterprise-search-form {
    border: 1px solid $border-color-3;

    &.form-control:focus {
      border-color: $base-text-color;
      box-shadow: none;
    }

    &.form-control:focus-visible {
      text-shadow: none;
    }

    &::placeholder {
      color: $border-color-3;
      letter-spacing: 0.5px;
    }

  }
}

.magnifying-glass:hover {
  cursor: pointer;
  transform: scale(1.05);
}

.search-box {
  position: relative;
  display: flex;
  align-items: center;

  width: 100%;
  max-width: 450px;
  height: 100%;

  input {
    width: 100%;
    border: 1px solid $border-color-3;
    border-radius: 25px;
    padding: 16px 15px 8px 20px;

    font-size: 16px;
    line-height: 1em;

    outline: 0;

    &:focus {
      border-color: $base-text-color;
    }
  }

  input::placeholder {
    color: white;
  }

  ::-webkit-input-placeholder {
    opacity: 0;
    transition: inherit;
  }

  input:focus::-webkit-input-placeholder {
    opacity: 1;
  }

  input:placeholder-shown + .floating-label,
  input:placeholder-shown + .search-btn-clear {
    top: 26px;
    left: 26px;
    visibility: visible;
    font-style: italic;

    color: $border-color-3;

    transform: translate3d(30px, 0px, 0) scale(1.7);
  }

  input:placeholder-shown {
    padding: 12px 15px 12px 20px;
  }

  .floating-label {
    position: absolute;
    top: 14px;
    left: 20px;

    font-size: 12px;
    color: $base-text-color-2;

    transition: all 0.1s ease-in-out;
    pointer-events: none;
  }

  .search-btn-clear {
    position: relative;
    top: 13px;
    right: 35px;
    visibility: hidden;

    font-size: 20px;
    color: $base-text-color-2;

    cursor: pointer;

    &:hover {
      color: $accent-color;
    }
  }

  .visible {
    visibility: visible;

    &:hover {
      background: none;
    }
  }
}

.advanced-search-icon {
  width: 23px;
  height: 23px;
  cursor: pointer;

  &:hover {
    stroke: $accent-color;
  }
}

/*----------------ADVANCE SEARCH---------------------*/

.advanced-search-el {
  display: flex;
  align-items: center;

  height: 50px;
  margin-bottom: 20px;

  & label {
    width: 150px;
    margin-bottom: 0;

    font-weight: 200;
    color: $base-text-color-2;
  }

  input {
    width: 100%;
    height: 100%;
    border: 1px solid $border-color-3;
    border-radius: 5px;
    padding: 13px 20px 7px;

    font-size: 16px;
    line-height: 1em;

    outline: 0;

    &:focus {
      border-color: $base-text-color;
    }
  }

  input::placeholder {
    color: black;
  }

  ::-webkit-input-placeholder {
    opacity: 0.3;
  }

  .search-btn-clear {
    position: relative;
    top: 2px;
    right: 35px;
    visibility: hidden;

    font-size: 20px;
    color: $base-text-color-2;

    cursor: pointer;

    &:hover {
      color: $accent-color;
    }
  }
}

.advanced-search-box {
  position: relative;

  .advanced-search-form-container {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 700px;
    margin-bottom: 30px;
  }
}

.advanced-search-btn-container {
  display: flex;
  height: 80px;
}

.loading-overlay {
  z-index: 2;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background-color: rgba(255, 255, 255, 0.5);
}
