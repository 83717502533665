@import '../../../styles/variables';

.wrapper {
  margin: 0 auto;
  max-width: 700px;
}

.title {
  font-size: 32px;
  font-weight: 500;
  line-height: 38px;
  margin-bottom:30px;
  padding-top: 14px;
}

.alert {
  color: $redesign-alert-text-color;
  margin: 30px 0px;

  svg {
    margin-right: 10px;
  }
}

.alertText {
  font-weight: 700;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;

  button {

    border: 1px solid $redesign-accent-color;
    border-radius: 4px;
    font-size: 20px;
    line-height: 30px;
    padding: 8px 0px;
    width: 325px;

    &:hover {
      opacity: .7;
    }
  }
}

.continueEditingButton {
  color: $redesign-accent-color;
  background-color: $color-white;
}

.createPlanButton {
  color: $color-white;
  background-color: $redesign-accent-color;
}