.create-form-container {
  display: block;

  width: 860px;
  margin: 0 auto;

  font-family: $labGrotesque;
}

.form-container {
  width: 860px;
  margin-top: 20px;

  & .form-el-wrapper {
    height: 45px;
    margin-bottom: 16px;

    & .form-input-wrapper {
      position: relative;
      display: inline-block;
      width: 70%;
    }
  }

  & .form-label {
    display: inline-block;

    width: 200px;
    margin-top: 0;
    margin-bottom: 0;

    font-size: 19px;
    font-weight: 600;
    line-height: 45px;
    color: $border-color-3;

  }

  & .form-input-error {
    margin-top: -12px;
    margin-left: 200px;
    color: $error-color;

    &:before {
      display: inline;
      content: '⚠ ';
    }
  }
}

.form-input {
  display: block;

  width: 100%;
  margin-bottom: 0;
  border: $border;
  border-radius: 4px;
  padding: 10px 15px;

  font-size: 15px;

  &:focus {
    border: 1px solid $base-a-color;
  }

  &.enterprise-select {
    padding: 6px 20px;
  }
}

.form-edit-container {
  margin-top: 0;
}

.form-edit-enterprise {
  position: relative;
  display: flex;
  align-items: center;

  margin-bottom: 10px;

  & .form-el-wrapper {
    width: 200px;

    & .form-input-wrapper {
      display: inline-block;

      & .form-input {
        padding: 4px 15px;
      }
    }
  }

  &.form-edit-array {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 20px;

    .form-array-el {
      display: flex;
      align-items: center;
      margin-bottom: 15px;

      & input {
        width: 300px;
        margin-right: 15px;
      }
    }

    & .inline-icon {
      margin: 0;
      font-size: 28px;
      color: $base-grey-color;

      &.inline-margin-bottom-icon {
        margin-bottom: 20px;
      }

      &:hover {
        color: $base-text-color-2;
      }
    }

    & .error {
      color: red;
      font-size: 12px;
    }

  }
}


.form-reset-password {
  & .form-label {
    width: 220px;
  }

  & .form-input-error {
    margin-left: 220px;
  }
}

.form-pass-icon {
  position: absolute;
  top: 32%;
  right: 5%;
}

.form-pass-icon:hover {
  color: $base-grey-color;
  cursor: pointer;
}

// firefox input type number can not have padding, it will cover +/- btn
@-moz-document url-prefix() {
  .form-type-number {
    height: 100%;
    padding: 0;

    line-height: 45px;
    text-indent: calc(20px);
  }
}