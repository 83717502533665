@import '../../../styles//variables';

.linkButton {
  background-color: $redesign-accent-color;
  border-radius: 4px;
  color: $color-white;
  display: block;
  margin-left: auto;
  padding: 6px 12px;
  width: 120px;
}

.linkButton:hover {
  color: $color-white;
  opacity: .7;
}