.small-margin-btn {
  margin-right: 15px;
  margin-left: 15px;
}

.form-btn-container {
  margin-top: 20px;
  text-align: center;

  & button:first-child {
    margin-right: 30px;
  }
}

.homepage-item-btn.btn {
  height: 32px !important;
  margin-bottom: 20px !important;
  margin: 0 0 0 8px !important;
  padding: 0 18px !important;
  text-transform: unset;
}

.dashboard-add-new-btn.btn,
.homepage-item-btn.btn {
  display: block;

  height: 50px;
  margin-bottom: 20px;
  border-radius: 25px;

  font-family: $labGrotesque;
  line-height: 30px;
  letter-spacing: 2.56px;

  background: $accent-color;

  &:hover {
    color: $color-white;
    background: mix(white, $accent-color, 20%);
  }

  &:active,
  &:focus,
  &:not(:disabled):not(.disabled):active {
    background: $accent-color;
  }
}

.edit-user-btn {
  font-size: 20px;
  color: $base-a-color;

  &:hover {
    color: $accent-color;
  }
}

.page-btn {
  border: 1px solid $base-grey-color-2;
  padding: 1px 19px;

  line-height: 18px;

  cursor: pointer;

  &:hover,
  &:focus,
  &:visited {
    outline: 0;

    & .page-btn-icon {
      color: $accent-color;
    }
  }

  & .disabled-btn {
    color: $base-grey-color-2 !important;
    cursor: unset;
  }

  & .page-btn-icon {
    height: 30px;
    font-size: 25px;
    color: $base-text-color-2;
  }
}

.form-btn-submit,
.advanced-search-submit {
  display: block;
  height: 45px;
  letter-spacing: 10px;
}

.form-btn-submit {
  margin: 30px auto 0;

  &:hover {
    color: $color-white;
    background: mix(white, $btn-color-primary, 20%);
  }

  &:active {
    transition: 0.3s all;
    transform: translateY(3px);
  }
}

.advanced-search-submit {
  width: 130px;
}

.advanced-search-btn-submit {
  margin-left: 120px;
}

.advanced-search-btn-clear {
  margin-left: 30px;
  text-transform: uppercase;

  &:hover {
    color: $color-white;
    background: mix(white, $base-grey-color, 20%);
  }
}

.btn,
.btn:focus {
  box-shadow: none !important;
}

.btn {
  border: none;
  border-radius: 5px;
  padding: 12px 20px;

  font-size: 15px;
  color: $color-white;
  text-align: center;
  letter-spacing: 5px;
  text-transform: uppercase;
}

.btn-primary:hover {
  color: $color-white;
  background: mix(white, $btn-color-primary, 20%);
}

.user-submit-btn:hover {
  background: mix(white, $btn-color-primary, 20%);
}

.reset-password-btn:hover {
  background: mix(white, $btn-color-success, 20%);
}

.danger-btn-margin {
  margin-right: 30px;
}

.danger-btn:hover,
.danger-btn:hover {
  background: mix(white, $error-color, 20%);
}

.page-error-btn:hover {
  background: mix(white, $base-text-color-2, 20%);
}

.danger-btn-box {
  display: block;
  margin-bottom: 20px;
  text-align: right;
}

.btn-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 250px;
  height: 140px;
  margin-top: 30px;

  & .edit-btn {
    width: 100px;
  }

  &.btn-cancel-sub-container {
    flex-direction: row;
    width: 650px;

    & .cancel-sub-btn {
      margin-right: 30px;
      height: 60px;
      width: 300px;
      font-size: 13px;
    }
  }
}

.small-btn {
  padding: 5px 7px;
  letter-spacing: 2.57px;

  &.small-add-btn {
    background-color: $base-text-color;

    &:hover {
      background: mix(white, $base-text-color, 20%);
    }
  }
}

.user-go-btn {
  text-decoration: underline;
  cursor: pointer;
}

.edit-horizontal-btn {
  display: flex;

  & button {
    margin-left: 0;
  }
}