// Base

@import 'mixins';

* {
  box-sizing: border-box;
}

html {
  font-family: $helvetica;
  font-size: $base-sm-font-size;

  @include breakpoint-large {
    font-size: $base-lg-font-size;
  }
}

body {
  overflow-x: visible;
}

a {
  color: $base-a-color;
}

a:link,
a:visited,
a:focus,
a:hover,
a:active {
  text-decoration: none;
  outline: 0;
}

figure {
  margin: 0;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  line-height: 1.3em;
}

h3 {
  font-size: 24.5px;
  line-height: 1.3em;
}

h4 {
  font-size: 20px;
}

ul {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

input,
button,
select,
textarea {
  font-family: $helvetica-neue;

  &:focus,
  &:active,
  &:visited {
    border: none;
    outline: 0;
  }
}
