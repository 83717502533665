// SET COLOR VARIABLES
//
// Accent color
$color-white: #fff;
$color-black: #000;
$accent-color: #096ffa;
//
// Colors from redesign
$redesign-accent-color: #007bff;
$redesign-alert-text-color: #212529;
$redesign-button-color-red: #D9534F;
$redesign-category-text-color: #373A3C;
$redesign-border-color: #dfdfdf;
$redesign-form-focus: #86b7fe;
$redesign-table-color: #F5F5F5;
$redesign-table-showing-color: #6C757D;
//
// Base font size
$base-sm-font-size: 14px;
$base-lg-font-size: 16px;
//
// Base colors
$base-text-color: #111;
$base-text-color-2: #4a4a4a;
$base-grey-color: #848f91;
$base-grey-color-2: #edeeef;
$base-grey-color-3: #fafafa;
$error-color: #be3b3a;
$bullet-color: #60bae0;
$btn-color-primary: #0069d9;
$btn-color-success: #28a745;
//
// Base link color
$base-a-color: #185f7d;
$base-a-hover-color: #299ecc;
$base-a-hover-box-shadow: 0 1px 0 0 $base-a-hover-color;
//
// Border
$border-color: #d8d8d8;
$border-color-2: #dceaec;
$border-color-3: #8ca8ac;
$border: 1px solid $border-color;
//
// Box shadow
$box-shadow: 0 1px 0 0 rgba(0, 0, 0, 0.1);
//
// Font families
$helvetica: Helvetica, Arial, sans-serif;
$helvetica-neue: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$labGrotesque: LabGrotesque, Helvetica, Arial, sans-serif;
//
// Responsive variables:
$bp-largest: 75rem; //1200px
$bp-large: 62.5rem; //1000px
$bp-medium: 56.25rem; //900px
$bp-small: 37.5rem; //600px
