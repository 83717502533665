.main-content {
  max-width: 1260px;
  height: 60vh;
  margin: 50px auto 100px;
}

.homepage {
  padding-bottom: 120px;
  .spinner {
    position: relative;
    width: auto;
    height: 300px;
  }

  .homepage-header-container {
    margin: 60px 0;
    & h1 {
      font-size: 90px;
      letter-spacing: 15px;
    }
    & p {
      letter-spacing: 2.56px;
      font-size: 30px;
    }
  }

  .homepage-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
    height: 40px;

    & > * {
      margin-right: 10px;
    }

    & > p {
      margin: 0;
    }
  }
}

.dashboard-head {
  display: flex;
  justify-content: space-between;
  align-items: center;

  height: 55px;
}

.page-show {
  margin: 0;

  & .page-show-select:focus {
    border: $border;
    box-shadow: none;
  }
}

.detail-container {
  position: relative;
  display: flex;
  height: 100%;

  & .detail-generation {
    margin-left: 80px;
  }

  & .detail-el {
    display: flex;

    & h3 {
      width: 200px;
      font-size: 20px;
      color: $border-color-3;
    }

    & .detail-align-center {
      display: flex;
      align-items: center;
    }

    &.enterprise-el {
      align-items: center;
      height: 28px;
      margin-bottom: 16px;
    }

    & .detail-no-margin {
      margin-bottom: 0;
    }
  }
}

.bi-arrow-icon {
  height: 10px;
  width: 10px;
}