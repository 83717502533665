@import '../../../styles/variables';

.button {
  background-color: transparent;
  border: none;
  padding: 0px;

  svg:hover {
    stroke: $redesign-accent-color;
  }
}