@import '../../../styles/variables';

.wrapper {
  margin: 0 auto;
  max-width: 700px;

  h1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
  }
}

.badge {
  font-size: 24px;
  font-weight: 500;
  line-height: 24px;
  margin: 14px 0px 16px;
}

.successMessageWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.overviewText {
  color: $redesign-table-showing-color;
  font-weight: 700;
}

.alert {
  color: $redesign-alert-text-color;
  margin: 30px 0px;

  svg {
    margin-right: 10px;
  }
}

.alertText {
  font-weight: 700;
}

.buttonWrapper {
  display: flex;
  justify-content: space-between;
}

.deletePlanButton, .viewAllPlansLink  {
  border-radius: 4px;
  font-size: 20px;
  font-weight: 400;
  line-height: 30px;
  padding: 8px 0px;
  width: 325px;

  &:hover {
    opacity: .7;
  }
}

.deletePlanButton {
  color: $redesign-button-color-red;
  background-color: $color-white;
  border: 1px solid $redesign-button-color-red;

  &:active, &:focus {
    border: 1px solid $redesign-button-color-red;
  }
}

.viewAllPlansLink {
  color: $color-white;
  background-color: $redesign-accent-color;
  border: 1px solid $redesign-accent-color;
  text-align: center;

  &:hover {
    color: $color-white;
  }
}